<template>
  <div class="disclaimer container">
    <div class="header">
      <div class="title-box">
        <p class="title">免责声明</p>
      </div>
    </div>
    <p class="title">免责声明</p>
    <div class="content">
      <div class="font-text" style="font-weight: bold; margin-top: -12px; padding-left: 4px;">
        请仔细阅读并理解本声明，使用牛牛资讯（以下简称“网站”），即表示您认可本声明的全部内容！
      </div>
      <div class="font-text font-require">除本网站注明的服务条款外，因使用网站遭致的包括但不限于意外、疏忽、延迟...而造成的一切经济损失，我们对此类情况概不负责任。</div>
      <div class="font-text font-require">通过网站而获得的任意第三方服务，包括但不限于广告、资讯、软件...，对其是否合法性及其造成的经济损失，本网站概不负任何经济或法律责任。</div>
      <div class="font-text font-require">任何由于黑客攻击、计算机病毒、政策法规、不可抗拒灾害...而造成的暂时性、突然性影响或损失，网站楖不负责任</div>
      <div class="font-text font-require">由于网站内容来源于互联网搜集、签约商提供、爱好者贡献等，我们对内容的健康性持严肃批判态度，一经发现立即关闭且保留追诉的权利</div>
      <div class="font-text font-require">当您认为本网站或网站提供的第三方服务可能存在侵权行为，请您务必及时通知网站，并提出有效权属证明。我们将会依法且尽快地查证并删除相关侵权内容</div>
      <div class="font-text font-require">由于互联网的复杂性、地域性...等原因，请您明确使用本网站是否有勃于当地法律法规，而我们对此类现象的态度是一贯坚决的说不！且不负任何法律责任。</div>
      <div class="font-text font-require">网站严格把控任何具有受屏蔽类关键字的广告、帖子、评论...等服务或作品，其仅代表该主体/作者之个人观点，不代表本网站。我们也保留追究的权利。</div>
      <!-- <h3 v-for="(p, i) in art.content" :key="i" style="margin-top:10px;margin-right:10px;margin-bottom:10px;margin-left:10px;text-indent:0;padding:0 0 0 0 ;line-height:35px">
        <span style="font-family: 微软雅黑; letter-spacing: 0px; font-weight: normal; background: rgb(255, 255, 255); font-size: 14px;">{{ p }}</span>
      </h3> -->
    </div>
  </div>
</template>

<script>
import { disclaimer } from '@/src/config/copywriting'
export default {
  name: 'Disclaimer',
  data() {
    return {
      art: disclaimer
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.disclaimer {
  padding-top: 30px;
  padding-bottom: 40px;

  >.header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 45px;

      >.title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  >.title {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 32px;
    font-weight: 400;
    color: #222;
    letter-spacing: 4px;
    text-align: center;
    background-color: #fff;
  }

  >.time {
    font-size: 14px;
    color: #959595;
    text-align: center;
    background-color: #fff;
  }

  >.content {
    margin: 0;
    padding: 80px 180px;
    padding-top: 0px;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 1px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
    background-color: #fff;
  }

  .font-text {}

  .font-require {
    padding-left: 18px;
    position: relative;

    &::before {
      content: '*';
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
