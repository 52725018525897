const companyName = '牛牛科技网络科技有限公司'
const websiteName = '牛牛资讯'
const skype = '168kaicom@gmail.com'
const email = '168kaicn@gmail.com'

export const about = {
  sub: '',
  content: [
  `${companyName}是一家多年完全致力于中国及世界各地公益彩票事业，提供全方位彩票开奖及彩票相关服务的提供商。旗下${websiteName}以中国市场为技术研发基地, 以互联网技术、电信技术、无线应用技术为依托，将创新的技术与传统的彩票产业相结合，面向全球彩票服务市场，提供最快最全的彩票开奖相关服务，包括彩票开奖结果调用、彩票系统的研发、彩票开奖移动客户端，及彩票产品创新等。`,
  `${websiteName}拥有一流的技术研发创新实力以及国内外多年的彩票网站运营及管理经验， 为中国以及全球彩票玩家提供一流的彩票创新产品及优质的服务。168彩票开奖网本着专业、诚信、服务至上的经营理念， 充分发挥机动灵活的运营 及管理机制，伴随着中国互联网及手机互联网时代的腾飞、彩票市场的不断繁荣，和各方客户的忠诚支持，将全面促进新型彩票服务市场的全盛发展。`
  ]
}

export const contact = {
  sub: '欢迎和我们联系，我们将会为您竭诚服务：',
  content: [
  `skype：${skype}`,
  `Email：${email}`
  ]
}

export const disclaimer = {
  sub: `${websiteName}提醒您：`,
  content: [
  `在使用${websiteName}之前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用${websiteName}，但如果您使用${websiteName}，您的使用行为将被视为对本声明全部内容的认可。`,
  `除${websiteName}注明之服务条款外，其他一切因使用${websiteName}而可能遭致的意外、疏忽、侵权及其造成的损失（包括因下载被搜索链接到的第三方网站内容而感染电脑病毒），${websiteName}对其概不负责，亦不承担任何法律责任。`,
  `任何通过使用${websiteName}而链接到的第三方网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，${websiteName}对其合法性概不负责，亦不承担任何法律责任。`,
  `任何单位或个人认为通过${websiteName}链接到的第三方网页内容可能涉嫌侵犯其信息网络传播权，应该及时向${websiteName}提出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。${websiteName}在收到上述法律文件后，将会依法尽快断开相关链接内容。详情参见特定频道的著作权保护声明。`,
  `用户明确同意其使用本网站网络服务所存在的风险将完全由其自己承担；因其使用本网站网络服务而产生的一切后果也由其自己承担，${websiteName}对用户不承担任何责任。`,
  '下列情况时本网站亦毋需承担任何责任：',
  '1、由于您将用户密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露及损失。',
  '2、任何由于黑客攻击、计算机病毒侵入或发作、因政府管理而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等。',
  '3、由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。',
  `4、本站各频道内容除标明“${websiteName}出品”外，其余内容皆由签约内容服务提供商（机构）、网友提供，本公司不承担由于内容的合法性及健康性所引起的一切争议和法律责任。如有疑异，请与本公司客服中心联系，我们将尽快处理。`,
  '5、本网站网页上有与其他网站网页的链接，包括旗帜广告和网页上的其他广告。本网对其他任何网站的内容、隐私政策或运营，或经营这些网站的公司的行为概不负责。在向这些与本网链接的网站提供个人信息之前，请查阅它们的隐私政策。',
  '6、本站所有帖子、评论仅代表作者个人观点，不代表本站。',
  '欢迎大家对侵犯版权等不合法和不健康的内容进行监督和举报。'
  ]
}

export default {
  about,
  contact,
  disclaimer
}
